.theme-top-con {
    margin-bottom: 20px;
}

.theme-color {
    display: flex;
    border: 1px solid #bfbfbf;
    width: auto;
    padding: 5px;
    &-item {
        width: 20px;
        height: 20px;
        margin: 0 2px;
    }
}

.add-dialog {
    width: 40vw !important;
    min-height: 50vh !important;
    &-top {
        display: flex;
        align-items: center;
    }
    &-con {
        margin-top: 20px;
    }
}
