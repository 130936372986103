.theme-color {
    display: flex;
    border: 1px solid #bfbfbf;
    width: auto;
    padding: 5px;
    &-item {
        width: 20px;
        height: 20px;
        margin: 0 2px;
    }
}
